import {User} from './service-provider-bids';

export interface SelectOptions {
  label: string;
  value: string;
}

export interface DeliveryResponse {
  count: number;
  enquiries: [];
}

export interface Notification {
  viewed: boolean;
  message: string;
  created_at: string;
}

export class Notifications {
  notifications: Notification [];
  unreadCount: number;

  constructor(opts: {
    notifications?: Notification [];
    unreadCount?: number;
  } = {}) {
    this.notifications = opts.notifications;
    this.unreadCount = opts.unreadCount;
  }
}

export interface DeliveryOptions {
  label: string;
  value: string;
  length: number;
  width: number;
  height: number;
}

export interface Location {
  lat: number;
  long: number;
  userId: string;
}

export interface TripData {
  enquiryId: number;
  category: string;
  spId: any;
}

// export class SelectOptions {
//   label: string;
//   code: string;

//   constructor(opts: {
//     label?: string,
//     code?: string,
//   } = {}) {
//     this.label = opts.label;
//     this.code = opts.code;
//   }
// }
export class VehicleResponse {
  id: number;
  total: number;
  vehicles: Vehicle[];
  commodities: Commodity[];
  types: any;

  constructor(opts: {
    id?: number;
    total?: number;
    vehicles?: Vehicle[];
    commodities?: Commodity[];
  } = {}) {
    this.id = opts.id;
    this.total = opts.total;
    this.vehicles = opts.vehicles;
    this.commodities = opts.commodities;
  }
}
export class NearByDriverResponse {
  spId: string;
  name: string;
  duration: number;
  distance: number;
  lat: number;
  long: number;

  constructor(opts: {
    spId?: string;
    name?: string;
    duration?: number;
    distance?: number;
    lat?: number;
    long?: number;
  } = {}) {
    this.spId = opts.spId;
    this.name = opts.name;
    this.duration = opts.duration;
    this.distance = opts.distance;
    this.lat = opts.lat;
    this.long = opts.long;
  }
}

export class Vehicle {
  id: number;
  name: string;
  photo: string;
  category: string;
  hasSubcategory: boolean;
  subcategoryId: number;
  // tslint:disable-next-line:variable-name
  min_bid_percent: any;
  brands: any;
  subCategory: [];

  constructor(opts: {
    id?: number;
    name?: string;
    photo?: string;
    category?: string;
    hasSubcategory?: boolean;
    subcategoryId?: number;
    subCategory?: [];
  } = {}) {
    this.id = opts.id;
    this.name = opts.name;
    this.photo = opts.photo;
    this.category = opts.category;
    this.hasSubcategory = opts.hasSubcategory;
    this.subcategoryId = opts.subcategoryId;
    this.subCategory = opts.subCategory;
  }
}

export class Commodity {
  id: number;
  name: string;
  photo: string;

  constructor(opts: {
    id?: number;
    name?: string;
    photo?: string;
  } = {}) {
    this.id = opts.id;
    this.name = opts.name;
    this.photo = opts.photo;
  }
}

export interface Places {
  country: any;
  fullAddress: any;
  locationId: number;
  location: {
    address: string;
    lat: number,
    lng: number
  };
  address: string;
}

export interface APIResponse {
  err: boolean;
  msg: string;
  data: object;
}

export class CategoryDetails {
  width: number;
  height: number;
  length: number;
  name: string;
  constructor(opts: {
    width?: number,
    height?: number,
    length?: number
    name?: string
  } = {}) {
    this.width = opts.width;
    this.height = opts.height;
    this.length = opts.length;
    this.name = opts.name;
  }
}

export class EnquiryResponse {
  sp: any;
  id: number;
  userId: string;
  clientCurrency: string;
  vehicleId: number;
  commodityId: number;
  vehicleSubId: number;
  commodity: any;
  tripsCount: number;
  KMPerDay: number;
  totalKM: number;
  hoursPerDay: number;
  lowestBids: any [];
  wonUser: any;
  days: number;
  // tslint:disable-next-line:variable-name
  commission_amount: number;
  // tslint:disable-next-line:variable-name
  total_km: number;
  // tslint:disable-next-line:variable-name
  brand_id: number;
  // tslint:disable-next-line:variable-name
  total_amount: number;
  final_amount:number;
  pickUpLocation: any;
  deliveryLocation: any;
  lat: number;
  long: number;
  fuel: boolean;
  driver: boolean;
  service: boolean;
  location: any;
  pickupLat: number;
  pickupLong: number;
  deliveryLat: number;
  deliveryLong: number;
  scheduleStart: string;
  scheduleEnd: string;
  auctionStart: string;
  auctionEnd: string;
  driverId: string;
  // tslint:disable-next-line:variable-name
  type_id: string;
  token: string;
  vehicle: any;
  // tslint:disable-next-line: variable-name
  created_at: string;
  // tslint:disable-next-line: variable-name
  updated_at: string;
  status: string;
  // tslint:disable-next-line:variable-name
  driver_assigned: User;
  spId: string;
  request: string;
  height: number;
  width: number;
  length: number;
  weight: string;
  distance_km: number;
  insurance: boolean;
  assistance: boolean;
  trip:any;
  brand: any;
  vehicleSubCategory: any;
  categoryDetails: CategoryDetails;
  immediate:boolean;
  convertCurrency: string;
  veh_year: string
  userCommissionDetail: {commission: number, tax: number, currency: string};
  no_driver_show_time: string | number;
  del_type: number;
  constructor(opts: {
    id?: number,
    userId?: string,
    clientCurrency?: string,
    vehicleId?: number,
    tripsCount?: number,
    sp?: any,
    insurance?: boolean,
    assistance?: boolean,
    commodityId?: number,
    vehicleSubId?: number,
    KMPerDay?: number,
    commodity?: any,
    totalKM?: number,
    hoursPerDay?: number,
    days?: number,
    lat?: number,
    wonUser?: any,
    location?: any,
    long?: number,
    brand_id?: number,
    fuel?: boolean,
    driver?: boolean,
    service?: boolean,
    pickupLat?: number,
    pickupLong?: number,
    deliveryLat?: number,
    deliveryLong?: number,
    scheduleStart?: string,
    scheduleEnd?: string,
    auctionStart?: string,
    auctionEnd?: string,
    created_at?: string,
    updated_at?: string,
    status?: string,
    driverId?: string
    type_id?: string,
    height?: number,
    width?: number,
    length?: number,
    weight?: string,
    lowestBids?: any[],
    token?: string,
    pickUpLocation?: any
    deliveryLocation?: any
    vehicle?: any;
    total_km?: number;
    total_amount?: number;
    final_amount?:number;
    commission_amount?: number;
    categoryDetails?: CategoryDetails;
    driver_assigned?: User;
    spId?: string;
    request?: string;
    distance_km?: number;
    trip?:any;
    immediate?: boolean;
    brand?: any;
    vehicleSubCategory?: any;
    convertCurrency?: string;
    userCommissionDetail?: {commission: number, tax: number, currency: string},
    veh_year?: string,
    no_driver_show_time?: string | number;
    del_type?: number;
  } = {}) {
    this.id = opts.id;
    this.userId = opts.userId;
    this.clientCurrency = opts.clientCurrency;
    this.vehicleId = opts.vehicleId;
    this.vehicleId = opts.pickupLat;
    this.tripsCount = opts.tripsCount || 0;
    this.commodityId = opts.commodityId;
    this.vehicleSubId = opts.vehicleSubId;
    this.tripsCount = opts.tripsCount || 0;
    this.KMPerDay = opts.KMPerDay || 0;
    this.totalKM = opts.totalKM || 0;
    this.hoursPerDay = opts.hoursPerDay || 0;
    this.days = opts.days || 0;
    this.lat = opts.lat;
    this.long = opts.long;
    this.insurance = opts.insurance;
    this.assistance = opts.assistance;
    this.fuel = opts.fuel;
    this.wonUser = opts.wonUser;
    this.driver = opts.driver;
    this.service = opts.service;
    this.pickupLat = opts.pickupLat;
    this.pickupLong = opts.pickupLong;
    this.deliveryLat = opts.deliveryLat;
    this.deliveryLong = opts.deliveryLong;
    this.scheduleStart = opts.scheduleStart;
    this.scheduleEnd = opts.scheduleEnd;
    this.auctionStart = opts.auctionStart;
    this.auctionEnd = opts.auctionEnd;
    this.created_at = opts.created_at;
    this.updated_at = opts.updated_at;
    this.brand_id = opts.brand_id;
    this.status = opts.status;
    this.driverId = opts.driverId;
    this.type_id = opts.type_id;
    this.token = opts.token;
    this.lowestBids = opts.lowestBids;
    this.pickUpLocation = opts.pickUpLocation || null;
    this.deliveryLocation = opts.deliveryLocation || null;
    this.vehicle = opts.vehicle;
    this.location = opts.location || null;
    this.total_km = opts.total_km || 0;
    this.total_amount = opts.total_amount || 0;
    this.final_amount= opts.final_amount || 0;
    this.commission_amount = opts.commission_amount || 0;
    this.driver_assigned = opts.driver_assigned;
    this.commodity = opts.commodity;
    this.sp = opts.sp;
    this.spId = opts.spId;
    this.request = opts.request;
    this.height = opts.height;
    this.width = opts.width;
    this.length = opts.length;
    this.weight = opts.weight;
    this.distance_km = opts.distance_km;
    this.categoryDetails = opts.categoryDetails;
    this.trip=opts.trip;
    this.immediate = opts.immediate;
    this.brand = opts.brand;
    this.vehicleSubCategory = opts.vehicleSubCategory;
    this.convertCurrency = opts.convertCurrency;
    this.userCommissionDetail = opts.userCommissionDetail;
    this.veh_year = opts.veh_year;
    this.no_driver_show_time = opts.no_driver_show_time;
    this.del_type = opts.del_type;
  }
}

export class CreateResponse {
  message: string;
  data: object;

  constructor(opts: {
    message?: string,
    data?: object
  } = {}) {
    this.message = opts.message;
    this.data = opts.data;
  }
}

export class Bids {
  id: number;
  biddingAmount: string;
  rating: object;
  bidId: any;
  serviceProvider: any;
  userId: any;
  convertAmount: string;
  convertCurrency: string;
  spCommissionDetail: {tax: number, commission: number, currency: string}

  constructor(opts: {
    id?: number;
    biddingAmount?: string,
    rating?: object,
    convertAmount?: string,
    convertCurrency?: string,
    spCommissionDetail?: {tax: number, commission: number, currency: string}
  } = {}) {
    this.id = opts.id;
    this.biddingAmount = opts.biddingAmount;
    this.rating = opts.rating;
    this.convertAmount = opts.convertAmount;
    this.convertCurrency = opts.convertCurrency;
    this.spCommissionDetail = opts.spCommissionDetail;
  }
}
