import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { map } from 'rxjs/operators';
import { TripData, Location } from '../models/common';

@Injectable({
  providedIn: 'root'
})

export class SocketService {
  constructor(private socket: Socket) {
  }

  ListEnquiries() {
    return this.socket.fromEvent('AUCTION').pipe(map(data => data));
  }

  enquiryUpdate(type) {
    return this.socket.fromEvent(type).pipe(map(data => data));
  }

  tripUpdate() {
    return this.socket.fromEvent<TripData>('TRIP').pipe(map(data => data));
  }
  listenPosition() {
    return this.socket.fromEvent<Location>('POSITION').pipe(map(data => data));
  }
  listenNotifications() {
    return this.socket.fromEvent('NOTIFICATION').pipe(map(data => data));
  }
}
