import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ConfirmationService } from 'primeng/api';
import {ConfirmDialogModule} from 'primeng/confirmdialog';

// @Injectable({
//   providedIn: 'root'
// })
// export class OnesignalService {

//   constructor() { }
// }
// import {Injectable} from '@angular/core';
// import {Cache} from '../utils/storage.provider'; // Decorator to access local storage

let OneSignal = window['OneSignal'] || [];

const url = '';

@Injectable({
  providedIn: 'root'
})
export class OneSignalService {
  [x: string]: any;

  constructor(
    private http: HttpClient,
    private confirmationService: ConfirmationService,
  ) {
  }

  subscribeNotification(oneSignalId) {
    return this.http.post('subscribe', { player_id: oneSignalId })
      .pipe(map((data) => data));
  }
  unSubscribeNotification(oneSignalId) {
    return this.http.post('unsubscribe',{ player_id: oneSignalId })
      .pipe(map((data) => data));
  }



}