// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiBaseUrl: 'https://api.logibids.com/v1/',
  socketUrl: 'https://api.logibids.com/',
  defaultLang: 'en',
  GOOGLE_API_KEY: 'AIzaSyBeJCUhMKMXbYzmmVgbg2TjZ3pke3yFBBE',
  baseUrl: 'https://api.logibids.com/v1/',
  path: '/firebase-messaging-sw.js',
  scope: '/',
  pusher: {
      key: '8a95c1ef06e601684784',
      cluster: "eu",
      authEndpoint: 'https://logibidsapi.appgrowthcompany.com/v1/pusher-authenticate'
  }
};

/**
 * https://devapi.logibids.com/
 * https://stagingapi.logibids.com/
 * https://api.logibids.com/
 */

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
