import { Injectable } from '@angular/core';
import {MapsAPILoader, MouseEvent} from '@agm/core';
@Injectable({
  providedIn: 'root'
})
export class CurrentCountryService {

  private geoCoder: google.maps.Geocoder;
  constructor(private mapsAPILoader: MapsAPILoader,) { }

  fetchCurrentCountry = (): Promise<any> => {
    return new Promise((resolve, reject) => {
      this.mapsAPILoader.load().then(() => {
        this.geoCoder = new google.maps.Geocoder;
        window.navigator.geolocation.getCurrentPosition((position) => {
          this.geoCoder.geocode({location: {lat: position.coords.latitude, lng: position.coords.longitude}}, (result, status) => {
            if (status === 'OK') {
              const resp = result[0].address_components.filter((ele) => ele.types.includes('country'));
              resolve(resp);
            }
          })
        })
      })
    })
  }
}
