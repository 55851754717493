import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {AuthService} from 'angularx-social-login';
import * as moment from 'moment';
import {Notifications, Notification} from '../../models/common';
import {ErrorHandlerService} from '../../services/error-handler.service';
import {AuthServicee as Authentication} from '../../services/auth.service';
import {SocketService} from '../../services/socket.service';
import {ConfirmationService} from 'primeng/api';
import {NgxSpinnerService} from 'ngx-spinner';
import {languageConstants} from '../../constants/languageConstants';
import { GeoLocationService } from '../../services/geo-location-service.service';
import { data } from 'jquery';
import {UserServiceService} from '../../services/user-service.service'
import { DeliveryCategory } from 'src/app/models/service-provider';


@Component({
  selector: 'logibids-user-headers',
  templateUrl: './user-headers.component.html',
  styleUrls: ['./user-headers.component.css']
})
export class UserHeadersComponent implements OnInit {
  dropdown: boolean;
  lan = localStorage.getItem('language') || 'en';
  language: boolean;
  country: boolean = false;
  user: any;
  privacy: boolean;
  page = 1;
  checked = true;
  unreadCount: number;
  moment = moment;
  language1 = 'en';
  languageConstants = languageConstants;
  languageKey = languageConstants[this.lan];
  notifications: Notification[] = [] ;
  cat: any;
  enqId: any;

  constructor(
    private spinner: NgxSpinnerService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private authService: AuthService,
    private userServiceService: UserServiceService,
    private AS: Authentication, private socket: SocketService,
    private errorService: ErrorHandlerService,
    private geo:  GeoLocationService
  ) {
    const user = localStorage.getItem('user');
    this.user = user && Object.keys(user).length ? JSON.parse(localStorage.getItem('user')) : null;
    const lang = localStorage.getItem('language');
    if (lang) {
      this.language1 = lang;
      this.languageKey = this.languageConstants[lang];
    }
  }

  ngOnInit() {
    this.getNotifications();
    this.getSockets();
    this.getRegions();
  }

  getSockets() {
    this.socket.listenNotifications().subscribe(data => {
      this.getNotifications();
    }, (error) => {
      this.errorService.handleError(error);
    });
  }

  getNotifications(page = 1) {
    this.AS.getUserNotifications(page).subscribe((notifications: Notifications) => {
      this.notifications = notifications.notifications;
      this.unreadCount = notifications.unreadCount;
    }, (err) => {
      this.errorService.handleError(err);
    });
  }

  viewedNotification() {
    this.AS.viewedNotification().subscribe(() => {
      this.getNotifications();
    }, (err) => {
      this.errorService.handleError(err);
    });
  }

  onScrollingFinished() {
    this.getNotifications(this.page + 1);
  }

  delete() {
    this.confirmationService.confirm({
      message: this.languageKey.account_message,
      acceptLabel: this.languageKey.acpt_lbl,
      rejectLabel: this.languageKey.rjct_lbl,
      key: 'dlt',
      accept: () => this.deleteUser()
    });
  }

  deleteUser() {
    this.spinner.show();
    this.AS.deleteAccount().subscribe(data => {
      this.spinner.hide();
      this.logout();
    }, (err) => {
      this.spinner.hide();
      this.errorService.handleError(err);
    });
  }

  showNav() {
    this.dropdown = !this.dropdown;
    this.language = false;
    this.privacy = false;
    this.country = false;
  }

  showLang() {
    this.language = !this.language;
    this.dropdown = false;
    this.privacy = false;
    this.country = false;
  }

  showCountry() {
    this.country = !this.country;
    this.dropdown = false;
    this.privacy = false;
    this.language = false;
  }

  selectedCountryId = '';
  onCountrySelect(resp: any) {
    this.selectedCountryId = resp.id;
  }

  showPrivacy() {
    this.privacy = !this.privacy;
    this.language = false;
    this.dropdown = false;
    this.country = false;
  }

  gotoPrivacy() {
    this.router.navigate(['/public/privacy']);
  }

  gotoTerms() {
    this.router.navigate(['/public/terms']);
  }

  gotoFaq() {
    this.router.navigate(['/public/faq']);
  }

  gotoContact() {
    this.router.navigate(['/public/contact']);
  }

  gotToFaq() {
    this.router.navigate(['/public/faq'])
  }

  gotoChangePassword() {
    this.router.navigate(['/my-profile/change-password'], {queryParams: { phoneNumber: this.user.phone_number }});
  }

  logout() {
    this.spinner.show();
      this.AS.logout().subscribe(() => {
        localStorage.clear();
        this.spinner.hide();
        this.authService.signOut(true);
        this.router.navigate(['/']);
        localStorage.clear();
        const that = this;
        setTimeout(function() {
          that.router.navigate(['/login'], {
            queryParams: {lang: 'en'}
          });
        }, 1000);
      })
  }

  setLanguage(language) {
    console.log("Id Token: ",localStorage.getItem('idToken'))
    if(this.user && this.user.preferredLanguage!=language){
      this.userServiceService.updateUserLanguage(language).subscribe((data:any)=>{
        if(data.idToken){
          localStorage.setItem('idToken',data.idToken)
          delete data.idToken
        }
        localStorage.setItem('user',JSON.stringify(data))
        const url = document.URL.split('/');
        url[3] = language;
        window.location.href = url.join('/');
      },(error)=>{
      })
    }else {
      const url = document.URL.split('/');
        url[3] = language;
        window.location.href = url.join('/');
    }
  }
  regions: any
  getRegions() {
    this.AS.getRegions().subscribe(async ({regions}: DeliveryCategory) => {
        this.regions = regions;
        console.log(this.regions);
    }, (err) => {
      this.errorService.handleError(err);
    });
}

  gotoMyProfile() {
    this.router.navigate(['/my-profile']);
  }

  gotoPostEnquiry() {
    this.router.navigate(['/vehicle']);
  }

  gotoHome() {
    this.router.navigate(['/my-enquiry']);
  }

  gotoMyWallet() {
    this.router.navigate(['/payment/wallet']);
  }

  gotoMyTrips() {
    this.router.navigate(['/my-trips']);
  }

  gotToMyRating() {
    this.router.navigate(['/my-rating']);
  }

getInfo(item) {
  debugger
         console.log(item)
          this.cat = item.payload.category;
          this.enqId = item.payload.enquiryId;
          if(item.payload.category == 'DELIVERY')
          {
            this.router.navigate([`my-enquiry/${(this.cat).toLowerCase()}/${this.enqId}/checkout`])
            console.log('Working!')
          }
          else{
          this.router.navigate([`my-enquiry/${(this.cat).toLowerCase()}/${this.enqId}`])
        }
      }
}
