import {Injectable} from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';

import {Observable, throwError} from 'rxjs';
import {map, catchError} from 'rxjs/operators';
import {Router, ActivatedRoute} from '@angular/router';

// import { AuthService } from 'app/services/auth.service'
import {environment} from '../../environments/environment';
import {ErrorHandlerService} from './error-handler.service';
import { CurrentCountryService } from './current-country.service';

@Injectable()
export class Backend implements HttpInterceptor {
  queryParams: object = {};

  constructor(
    // private auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private errorHandler: ErrorHandlerService,
    private ccc: CurrentCountryService
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let newReq = request;
    const accessToken = localStorage.getItem('accessToken');
    const idToken = localStorage.getItem('idToken');

    if (accessToken) {
      newReq = newReq.clone({headers: newReq.headers.set('Authorization', `JWT ${accessToken}`)});
    }
    if (idToken) {
      newReq = newReq.clone({headers: newReq.headers.set('ID', `${idToken}`)});
    }

    this.ccc.fetchCurrentCountry().then((res) => {
      console.log(res);
    });

    const language = localStorage.getItem('language') || 'en';
    const currencyCode = localStorage.getItem('currencyCode') || 'INR';
    newReq = newReq.clone({url: `${environment.apiBaseUrl}${newReq.url}`});
    newReq = newReq.clone({headers: newReq.headers.set('Accept-Language', language)});
    newReq = newReq.clone({headers: newReq.headers.set('currencyCode', currencyCode)});


    return next.handle(newReq).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          event = event.clone({body: event.body.data});
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        let data = {};
        data = {
          reason: error && error.error && error.error.reason ? error.error.reason : '',
          status: error.status
        };
        if (!error.url.includes('refresh-token') && !error.url.includes('login')) {
          this.errorHandler.handleError(error);
        }
        return throwError(error);
      }));
  }
}
