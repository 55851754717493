import { NgModule, ModuleWithProviders } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule } from '@angular/common';

import { Backend } from './backend';
import { FormService } from './form.service';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: []
})

export class ServicesModule {
  static forRoot() {
    return {
      ngModule: ServicesModule,
      providers: [
        FormService,
        { provide: HTTP_INTERCEPTORS, useClass: Backend, multi: true },
      ]
    };
  }
}
