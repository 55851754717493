import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthServicee } from './auth.service';
import { MessageService } from 'primeng/api';

@Injectable()
export class ErrorHandlerService {
  public errorMessage = '';

  constructor(
    private ms: MessageService,
    private router: Router,
    private auth: AuthServicee
  ) {
  }

  public handleError(error: HttpErrorResponse) {

    if (error.status === 500) {
      this.handle500Error(error);
    } else if (error.status === 401) {
      this.handle401Error(error);
    } else if (error.status === 404) {
      this.handle404Error(error);
    } else {
      this.handleOtherError(error);
    }
  }

  private handle500Error(error: HttpErrorResponse) {
    this.createErrorMessage(error);
    // this.router.navigate(['/public/server-error']);
  }

  private handle404Error(error: HttpErrorResponse) {
    this.createErrorMessage(error);
    // this.router.navigate(['/public/maintenance']);
  }

  private handle401Error(error: HttpErrorResponse) {
    this.createErrorMessage(error);
    if (error.error.msg !== 'Old Password is incorrect.') {
    this.auth.refreshToken().subscribe(opts => {
      localStorage.setItem('user', JSON.stringify(opts.user));
      localStorage.setItem('accessToken', opts.accessToken);
      localStorage.setItem('idToken', opts.idToken);
      window.location.reload();
    }, (error) => {
      localStorage.clear();
      this.router.navigate(['/public/unauthorized']);
    });
  }
  }

  private handleOtherError(error: HttpErrorResponse) {
    this.createErrorMessage(error);
    // TODO: this will be fixed later;
  }

  private createErrorMessage(error: HttpErrorResponse) {
    console.log(error.error.msg, 'from error page')
    this.errorMessage = error.error.msg ? error.error.msg : error.error.msg;
  }

}
