import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {EnquiryResponse, APIResponse, VehicleResponse, NearByDriverResponse} from '../models/common';

@Injectable({
  providedIn: 'root'
})
export class PostEnquiryService {
  constructor(private http: HttpClient) {
  }

  fetchSubCategoryByBrand(brandId: number, vehicleId: string) {
    return this.http.get<any>(`vehicle-model?brand_id=${brandId}&vehicleId=${vehicleId}`);
  }

  fetchVehicleYears(body: any) {
    let httpParams = new HttpParams();
    if (body.type_id) {
      httpParams = httpParams.set('type_id', body.type_id)
    }
    if (body.brand_id) {
      httpParams = httpParams.set('brand_id', body.brand_id)
    }
    if (body.vehicleId) {
      httpParams = httpParams.set('vehicleId', body.vehicleId)
    }
    if (body.vehicleSubId) {
      httpParams = httpParams.set('vehicleSubId', body.vehicleSubId)
    }
    return this.http.get<any>(`vehicle-years`, { params: httpParams });
  }

  submitTransportPublic(param) {
    return this.http.post<EnquiryResponse>('enquiry/transport/public', param)
      .pipe(map(data => new EnquiryResponse(data)));
  }

  submitTransportGoods(param) {
    return this.http.post<EnquiryResponse>('enquiry/transport/goods', param)
      .pipe(map(data => new EnquiryResponse(data)));
  }

  submitRentalHeavy(param) {
    param['enquiry_type'] = 'RENTAL-HEAVY'
    return this.http.post<EnquiryResponse>('enquiry/rental/heavy', param)
      .pipe(map(data => new EnquiryResponse(data)));
  }

  submitRentalGoods(param) {
    return this.http.post<EnquiryResponse>('enquiry/rental/goods', param)
      .pipe(map(data => new EnquiryResponse(data)));
  }

  submitRentalOthers(param) {
    return this.http.post<EnquiryResponse>('enquiry/rental/others', param)
      .pipe(map(data => new EnquiryResponse(data)));
  }

  submitRentalTrailer(param) {
    param['enquiry_type'] = 'RENTAL-TRAILER ONLY';
    return this.http.post<EnquiryResponse>('enquiry/rental/heavy', param)
    .pipe(map(data =>  new EnquiryResponse(data)));
  }

  submitGenerator(param) {
    param['enquiry_type'] = 'RENTAL-GENERATOR';
    return this.http.post<EnquiryResponse>('enquiry/rental/heavy', param)
    .pipe(map(data =>  new EnquiryResponse(data)));
  }

  submitDelivery(param) {
    return this.http.post<EnquiryResponse>('enquiry/delivery', param)
      .pipe(map(data => new EnquiryResponse(data)));
  }

  getLocationId(param) {
    return this.http.post('location', param)
      .pipe(map(data => data));
  }

  fetchVehicles(param = '') {
    return this.http.get<VehicleResponse>('vehicle', {
      params: {
        search: param
      }
    }).pipe(map(data => new VehicleResponse(data)));
  }

  fetchTypeVehicles(param = '', type, brand = null) {
    const opt = brand == null ? {type_id: type, search: param} : {type_id: type, brand_id: brand, search: param};
    return this.http.get('vehicle', {params: opt}).pipe(map(data => new VehicleResponse(data)));
  }

  fetchCommodity(param = '',category='') {
    return this.http.get<VehicleResponse>('commodity', {
      params: {
        search: param,
        category: category
      }
    })
      .pipe(map(data => new VehicleResponse(data)));
  }

  getLocaleDrivers({lat = '', lng = ''}, categoryId) {
    const body = {pickupLat: lat, pickupLong: lng, range_in_meter: 10000, categoryId};
    return this.http.post('drivers-nearby', body)
      .pipe(map(data => data));
  }

  getPrice() {
    return this.http.get('delivery-charge').pipe(map(data => data));
  }
}
