import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'logibids-dash-footer',
  templateUrl: './dash-footer.component.html',
  styleUrls: ['./dash-footer.component.css']
})
export class DashFooterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
